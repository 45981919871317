import classes from "./SustrumBanners.module.scss";
import NiederlangenBanner from "../../assets/Images/Banners/NiederlangenBanner.png";
import OberlangenBanner from "../../assets/Images/Banners/OberlangenBanner.png";
import SustrumBanner from "../../assets/Images/Banners/SustrumBanner.png";
import LandeggeBanner from "../../assets/Images/Banners/LandeggeBanner.png";
import BannerFile from "./BannerFile/BannerFile";

const SustrumBanners = () => {
	return (
		<div className={classes.container}>
			<BannerFile image={NiederlangenBanner} title="Niederlangen"></BannerFile>
			<BannerFile image={OberlangenBanner} title="Oberlangen"></BannerFile>
			<BannerFile image={SustrumBanner} title="Sustrum"></BannerFile>
			<BannerFile image={LandeggeBanner} title="Landegge"></BannerFile>
		</div>
	);
};

export default SustrumBanners;
