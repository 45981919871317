import classes from "./BlogCard.module.css";
import Divider from "../Divider/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const BlogCard = (props: {
	classes?: string;
	image: string;
	name: string;
	degrees?: string[];
	degree?: string;
	roles?: string[];
	role?: string;
	email: string;
}) => {
	//Depending on whether the roles or degrees are consisting of several rows or not.
	let degrees = null;
	let degree = null;
	let roles = null;
	let role = null;
	if (props.degrees !== undefined) {
		degrees = props.degrees.map((el) => {
			return <p>{el}</p>;
		});
	}
	if (props.roles !== undefined) {
		roles = props.roles.map((el) => {
			return <p>{el}</p>;
		});
	}
	if (props.degree !== undefined) {
		degree = <p>{props.degree}</p>;
	}
	if (props.role !== undefined) {
		role = <p>{props.role}</p>;
	}

	if (props.roles === undefined && props.role === undefined) {
		role = <div style={{ height: "1rem", width: "100%" }} />;
	}

	const text = (
		<>
			{degrees}
			{degree}
			{roles}
			{role}
		</>
	);

	const overlay = (
		<div className={classes.overlay}>
			<h2 className="mt-50 mb-50">{props.name}</h2>
			<Divider margin={false} />

			<a className={"mt-50 " + classes.contact} href={"mailto:" + props.email}>
				<FontAwesomeIcon icon={faEnvelope} /> Kontakt
			</a>
		</div>
	);

	return (
		<div className={clsx(props.classes)}>
			<div className={"mt-100 " + classes.container}>
				<div className={classes.wrapper}>
					{overlay}
					<img
						src={props.image}
						className={classes.image}
						alt={"Profile Card of " + props.name}
						loading="lazy"
					/>
					<div className={classes.textSection + " mb-25"}>
						<h2 className="mb-25">{props.name}</h2>
						{text}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogCard;
