import { useState } from "react";
import Modal from "../Components/PDFModal/PDFModal";
import styled from "styled-components";

const Button = styled.button`
	font-size: 24px;
	height: 80px;
	width: 100%;
	font-weight: 700;
	background-color: var(--secondaryColor);
	border: none;
	border-radius: 10px;
	color: white;
	cursor: pointer;
	padding: 0 30px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		color: white;
		text-decoration: underline;
	}
`;

const SingleDownload = (props: {
	downloadLink: string;
	text?: string;
}) => {
	const [modalVisibility, changeModalVisibility] = useState(false);

	return (
		<>
			<Modal
				file={props.downloadLink}
				visibility={modalVisibility}
				changeVisibility={changeModalVisibility}
			/>
			<Button
				type="button"
				className={"container"}
				onClick={() => {
					changeModalVisibility(!modalVisibility);
				}}>
				{props.text ?? "Download Dorfentwicklungsplan"}
			</Button>
		</>
	);
};

export default SingleDownload;
