import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "../Schwerpunkte.module.css";

const Schwerpunkt = (props: { icon: any; heading: string }) => {
	return (
		<>
			<div className={classes.Kategorie + " colB-4"}>
				<FontAwesomeIcon
					icon={props.icon}
					className={classes.icon + " col-2-sm"}
				/>
				<h3 className={"col-10-sm"}>{props.heading}</h3>
			</div>
		</>
	);
};

export default Schwerpunkt;
