import { useEffect } from "react";
import classes from "./NavigationButton.module.scss";
import disableScrolling from "../../Components/DisableScrolling/DisableScrolling";
import Hamburger from "./Hamburger/Hamburger";
import clsx from "clsx";
import { MainMenu } from "./Menu/MainMenu";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";

interface INavigationButtonProps {
	open: boolean;
	toggleOpen: Function;
	mainMenu: boolean;
}
export const NavigationButton = (props: INavigationButtonProps) => {
	const [isMenuOpen, toggleMenu] = [props.open, props.toggleOpen];

	const Breakpoint1 = useMediaQuery(`(min-width: ${780}px)`);
	const Breakpoint2 = useMediaQuery(`(min-width: ${680}px)`);
	const Breakpoint3 = useMediaQuery(`(max-width: ${520}px)`);
	useEffect(() => {
		//disableScrolling(isMenuOpen);
	}, [isMenuOpen]);

	return (
		<>
			<div
				className={clsx(classes.wrapper, isMenuOpen && classes.closer)}
				onClick={() => {
					toggleMenu(!isMenuOpen);
				}}>
				{(Breakpoint2 || Breakpoint3) && (
					<p
						className={clsx(
							classes.toggleMenuText,
							isMenuOpen && classes.alternateText
						)}>
						{Breakpoint1 && "Unsere Dorfregionen"}
						{Breakpoint2 && !Breakpoint1 && "Dorfregionen"}
						{Breakpoint3 && !Breakpoint2 && "Dorfregionen"}
					</p>
				)}
				<Hamburger clicked={isMenuOpen} className={classes.hamburger} />
			</div>
			<div
				className={clsx(
					classes.contentContainer,
					isMenuOpen && classes.toggledContentContainer
				)}>
				{props.mainMenu && <MainMenu />}
			</div>
			<div
				className={clsx(isMenuOpen && classes.backdropOpen, classes.backdrop)}
				onClick={() => {
					toggleMenu(false);
				}}></div>
		</>
	);
};
