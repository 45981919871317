const arrowDown = (props: { classes: string }) => {
	return (
		<svg
			className={props.classes}
			height="110px"
			viewBox="0 0 165 110"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink">
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd">
				<g
					id="Artboard-Copy-4"
					transform="translate(-1360.000000, -339.000000)"
					fill="#444444">
					<path
						d="M1448.7114,343.436426 L1522.92984,433.065166 C1525.73518,436.452988 1525.27929,441.470119 1521.90926,444.296805 L1517.66248,447.858879 C1514.27628,450.695096 1509.23191,450.253775 1506.38965,446.872642 L1448.62704,378.06096 C1445.78637,374.676914 1440.74024,374.236421 1437.35619,377.077092 C1437.00027,377.37586 1436.67106,377.705071 1436.3723,378.060991 L1378.61032,446.872617 C1375.7681,450.253801 1370.72371,450.695137 1367.33752,447.858879 L1363.09449,444.29995 C1359.72299,441.47203 1359.2684,436.452094 1362.07705,433.06452 L1436.39113,343.432635 C1439.21114,340.031355 1444.2545,339.560144 1447.65578,342.380156 C1448.03982,342.698563 1448.39323,343.052193 1448.7114,343.436426 Z"
						id="Arrow-Copy"
						transform="translate(1442.500000, 394.500000) scale(1, -1) translate(-1442.500000, -394.500000) "></path>
				</g>
			</g>
		</svg>
	);
};

export default arrowDown;
