import React from "react";
import Image from "../../assets/Images/Banner1.jpg";

const ImagePattern = (props: { classes?: string }) => {
	let [path, changePath]: any = React.useState([]);

	let [[x, y, width, height], changeImageValues] = React.useState([
		"-12",
		"-651",
		"1605",
		"1238",
	]);

	let [[viewbox, svgWidth, svgHeight], changeSVGSizes] = React.useState([
		"0 0 1540 454",
		"1537px",
		"457px",
	]);

	const resize = () => {
		if (window.innerWidth > 290 && window.innerWidth <= 830 && path[0] !== 1) {
			changeImageValues(["-12", "-651", "1605", "1238"]);
			changePath([
				1,
				<path
					d="M652.842106,281 L702,367.5 L652.842106,454 L554.135762,454 L504,367.158718 L554.135762,281 L652.842106,281 Z M316.842106,280 L366,366.5 L316.842106,453 L218.135762,453 L168,366.158718 L218.135762,280 L316.842106,280 Z M1490.84211,367 L1539.715,453 L1342.092,453 L1392.13576,367 L1490.84211,367 Z M148.842106,366 L198,452.5 L197.716,453 L0.486,453 L0,452.158718 L50.135762,366 L148.842106,366 Z M988.842106,280 L1038,366.5 L988.842106,453 L890.135762,453 L840,366.158718 L890.135762,280 L988.842106,280 Z M820.842106,366 L870,452.5 L869.716,453 L672.486,453 L672,452.158718 L722.135762,366 L820.842106,366 Z M484.842106,366 L534,452.5 L533.716,453 L336.486,453 L336,452.158718 L386.135762,366 L484.842106,366 Z M1157.84211,366 L1206,452.5 L1205.716,453 L1008.486,453 L1008,452.158718 L1058.13576,366 L1157.84211,366 Z M1322.84211,280 L1372,366.5 L1322.84211,453 L1224.13576,453 L1174,366.158718 L1224.13576,280 L1322.84211,280 Z M484.842106,183 L534,269.5 L484.842106,356 L386.135762,356 L336,269.158718 L386.135762,183 L484.842106,183 Z M1156.84211,183 L1206,269.5 L1156.84211,356 L1058.13576,356 L1008,269.158718 L1058.13576,183 L1156.84211,183 Z M820.842106,183 L870,269.5 L820.842106,356 L722.135762,356 L672,269.158718 L722.135762,183 L820.842106,183 Z M652.842106,98 L702,184.5 L652.842106,271 L554.135762,271 L504,184.158718 L554.135762,98 L652.842106,98 Z M988.842106,97 L1038,183.5 L988.842106,270 L890.135762,270 L840,183.158718 L890.135762,97 L988.842106,97 Z M820.842106,0 L870,86.5 L820.842106,173 L722.135762,173 L672,86.1587182 L722.135762,0 L820.842106,0 Z"
					id="path-1"></path>,
			]);
			changeSVGSizes(["0 0 1540 454", "1537px", "457px"]);
		} else if (window.innerWidth <= 290 && path[0] !== 2) {
			changeImageValues(["-12", "-651", "1605", "1238"]);
			changePath([2, <path></path>]);
		} else if (window.innerWidth > 830 && path[0] !== 3) {
			changeImageValues(["-410", "-317", "1540", "1155"]);
			changePath([
				3,
				<path
					d="M461.743324,800 L363.064475,800 L312.742915,713.555992 L363.064475,627.111984 L461.743324,627.111984 L510.887525,713.555992 L461.743324,800 Z M615.855799,537.524558 L665,623.968566 L615.855799,710.412574 L517.17695,710.412574 L466.85539,623.968566 L517.17695,537.524558 L615.855799,537.524558 Z M461.743324,446.365422 L510.887525,532.80943 L461.743324,619.253438 L363.064475,619.253438 L312.742915,532.80943 L363.064475,446.365422 L461.743324,446.365422 Z M665,443.222004 L615.855799,529.666012 L517.17695,529.666012 L466.85539,443.222004 L517.17695,356.777996 L615.855799,356.777996 L665,443.222004 Z M461.743324,267.19057 L510.887525,353.634578 L461.743324,440.078585 L363.064475,440.078585 L312.742915,353.634578 L363.064475,267.19057 L461.743324,267.19057 Z M304.485697,179.174853 L353.629898,265.618861 L304.485697,352.062868 L205.806848,352.062868 L155.485288,265.618861 L205.806848,179.174853 L304.485697,179.174853 Z M615.855799,178.305488 L665,264.047151 L615.855799,350.491159 L517.17695,350.491159 L466.85539,264.047151 L517.17695,177.603143 L615.652603,177.603143 L615.855799,178.305488 Z M460.170748,89.5874263 L509.314949,176.031434 L460.170748,262.475442 L361.491899,262.475442 L311.170339,176.031434 L361.491899,89.5874263 L460.170748,89.5874263 Z M663.427424,86.4440079 L614.283222,172.888016 L515.604373,172.888016 L465.282813,86.4440079 L515.604373,-1.13686838e-13 L614.283222,-1.13686838e-13 L663.427424,86.4440079 Z M205.806848,710.412574 L155.485288,623.968566 L205.806848,537.524558 L304.485697,537.524558 L353.629898,623.968566 L304.485697,710.412574 L205.806848,710.412574 Z M154.68457,444.793713 L205.806848,358.349705 L304.485697,358.349705 L353.629898,444.793713 L304.485697,531.237721 L205.806848,531.237721 L154.68457,444.793713 Z M50.1217966,443.222004 L0,356.436935 L50.1217966,270.333988 L148.800646,270.333988 L197.944847,356.777996 L148.800646,443.222004 L50.1217966,443.222004 Z"
					id="path-1"></path>,
			]);
			changeSVGSizes(["0 0 665 800", "665px", "800px"]);
		}
	};

	window.onresize = resize;

	React.useEffect(resize, [path]);

	return (
		<div>
			<svg
				className={props.classes}
				width={svgWidth}
				height={svgHeight}
				viewBox={viewbox}
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink">
				<title>Banner Image</title>

				<mask id="mask2" fill="white">
					{path[1]}
				</mask>
				<image
					id="thumbnail"
					mask="url(#mask2)"
					x={x}
					y={y}
					width={width}
					height={height}
					xlinkHref={Image}
				/>
			</svg>
		</div>
	);
};

export default ImagePattern;
