import classes from "./Hamburger.module.scss";
import clsx from "clsx";

interface IHamburgerProps {
	clicked: boolean;
	className?: string;
}
const Hamburger = (props: IHamburgerProps) => {
	// const [clicked, setClicked]: [boolean, Function] = useState(false);
	return (
		<div
			className={clsx(classes.hamburger, props.className)}
			// onClick={() => {
			// 	setClicked(!clicked);
			// }}
		>
			<div
				className={clsx(classes.rec1, props.clicked && classes.clicked)}></div>
			<div
				className={clsx(classes.rec2, props.clicked && classes.clicked)}></div>
			<div
				className={clsx(classes.rec3, props.clicked && classes.clicked)}></div>
		</div>
	);
};

export default Hamburger;
