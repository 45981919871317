import classes from "./Team.module.scss";
import Divider from "../../Components/Divider/Divider";
import TeamMember from "../../Components/BlogCard/BlogCard";
import TeamData from "../../data/our_team";

const Team = () => {
	const teamMembers = TeamData.map((el, index: number) => {
		return (
			<div className="col-6" key={index}>
				<TeamMember
					image={el.image}
					name={el.name}
					degree={el.degree}
					degrees={el.degrees}
					role={el.role}
					roles={el.roles}
					email={el.email}
				/>
			</div>
		);
	});

	return (
		<>
			<Divider />
			<h2>
				UNSER <span className="primaryColor">TEAM</span>
			</h2>
			<div className={classes.wrapper}>
				<span className={classes.item}>{teamMembers[0]}</span>
				<span className={classes.item}>{teamMembers[1]}</span>
				<span className={classes.item}>{teamMembers[2]}</span>
				<span className={classes.item}>{teamMembers[3]}</span>
				<span className={classes.item}>{teamMembers[4]}</span>
				<span className={classes.item}>{teamMembers[5]}</span>
			</div>
		</>
	);
};

export default Team;
