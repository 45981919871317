import AndreasBrinkerJPEG from "../assets/Images/AndreasBrinkerSquare.jpg";
import CarlaSchmidtJPEG from "../assets/Images/CarlaSchmidtSquare.jpg";
import JörnThiemannJPEG from "../assets/Images/JörnThiemannSquare.jpg";
import MareenKoopsJPEG from "../assets/Images/MareenKoopsSquare.jpg";
import SonjaKamphusJPEG from "../assets/Images/SonjaKamphusSquare.jpg";
import SandraWeigandJPEG from "../assets/Images/SandraWeigandSquare.jpg";

const our_team = [
	{
		name: "Dr. Carla Schmidt",
		degrees: ["Soziologie", "Sozialwissenschaften"],
		role: "",
		email: "schmidt@regionalplan-uvp.de",
		image: CarlaSchmidtJPEG,
		imageCoordinates: {
			x: "-150",
			y: "-150",
			width: "1034",
			height: "1549",
		},
	},
	{
		name: "Jörn Thiemann",
		degree: "Dipl.-Ing. (FH)",
		role: "Landschaftsentwicklung",
		email: "thiemann@regionalplan-uvp.de",
		image: JörnThiemannJPEG,
		imageCoordinates: {
			x: "-109",
			y: "-187",
			width: "944",
			height: "1414",
		},
	},
	{
		name: "Sandra Weigand",
		roles: ["Regional- und Dorfentwicklungsplanung"],
		email: "weigand@regionalplan-uvp.de",
		image: SandraWeigandJPEG,
		imageCoordinates: {
		},
	},
	{
		name: "Andreas Brinker",
		degree: "Dipl.-Ing. (FH) Landespflege",
		roles: ["Landwirt"],
		email: "brinker@regionalplan-uvp.de",
		image: AndreasBrinkerJPEG,
		imageCoordinates: {
			x: "-217",
			y: "-262",
			width: "1052",
			height: "1577",
		},
	},
	{
		name: "Sonja Kamphus",
		roles: ["Regional- und Dorfentwicklungsplanung"],
		email: "kamphus@regionalplan-uvp.de",
		image: SonjaKamphusJPEG,
		imageCoordinates: {},
	},
	{
		name: "Mareen Koops",
		degree: "M.Sc. Nachhaltigkeitsgeographie",
		email: "koops@regionalplan-uvp.de",
		image: MareenKoopsJPEG,
		imageCoordinates: {},
	},
];

export default our_team;
