import React from "react";
import classes from "./Navbar.module.scss";
import Logo from "./logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { NavigationButton } from "./NavigationButton/NavigationButton";
import { useMediaQuery } from "../CustomHooks/UseMediaQuery";
import {NavLink} from "react-router-dom";

const NavBar = () => {
	const [open, toggleOpen] = React.useState(false);

	const phoneIcon = (
		<FontAwesomeIcon icon={faPhoneSquare} className={classes.icon} />
	);
	const envelopeIcon = (
		<FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
	);

	const visibilityStyle = {};

	const Breakpoint = useMediaQuery(`(min-width: ${520}px)`);

	return (
		<>
			<div className={classes.upperNavbar} style={visibilityStyle}>
				<div className={classes.NavbarContact}>
					{phoneIcon}
					<p>05902 / 503702-0</p>
					{envelopeIcon}
					<a href="mailto:info@regionalplan-uvp.de">info@regionalplan-uvp.de</a>
				</div>
			</div>
			<div className={classes.border1} style={visibilityStyle}></div>
			<div className={classes.border2} style={visibilityStyle}></div>

			<>
				<div className={classes.lowerMainNavbar} style={visibilityStyle}>
					<div className={classes.lowerMainNavbarContent}>
						<>
							{Breakpoint && (
								<div className={classes.logo}>
									<NavLink to={`/`}>
										<Logo />
									</NavLink>
								</div>
							)}
							<div className={classes.menubar}>
								<span className={classes.hamburger}>
									<NavigationButton
										mainMenu={true}
										open={open}
										toggleOpen={toggleOpen}
									/>
								</span>
							</div>
						</>
					</div>
				</div>
				<div className={classes.mainNavbarBorder}></div>
			</>
		</>
	);
};

export default NavBar;
