const TopArrowSVG = (props: { clicked: any }) => {
	return (
		<svg
			width="206px"
			height="206px"
			viewBox="0 0 206 206"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			onClick={props.clicked}>
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd">
				<g id="Artboard" transform="translate(-224.000000, -180.000000)">
					<g id="ScrollTopArrow" transform="translate(224.000000, 180.000000)">
						<circle
							id="Circle"
							fill="#000000"
							cx="103"
							cy="103"
							r="103"></circle>
						<path
							d="M109.111748,48.0961743 L183.330192,137.724914 C186.13553,141.112736 185.679639,146.129867 182.309608,148.956553 L178.062833,152.518627 C174.676627,155.354844 169.632262,154.913523 166.789998,151.53239 L109.02739,82.720708 C106.186719,79.3366617 101.140589,78.8961689 97.7565423,81.7368398 C97.4006244,82.035608 97.0714149,82.3648192 96.7726485,82.7207385 L39.0106714,151.532364 C36.1684455,154.913549 31.1240595,155.354885 27.7378669,152.518627 L23.4948417,148.959698 C20.1233397,146.131778 19.6687514,141.111841 22.4773994,137.724268 L96.7914803,48.0923833 C99.6114929,44.6911027 104.654847,44.2198918 108.056128,47.0399044 C108.440165,47.3583109 108.793578,47.7119412 109.111748,48.0961743 Z"
							id="Arrow"
							fill="#FFFFFF"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default TopArrowSVG;
