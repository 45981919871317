import React from "react";
import classes from "./ModalButton.module.css";
import disableScrolling from "../DisableScrolling/DisableScrolling";
import Modal from "../Modal/Modal";

const ModalButton = (props: {
	children?: JSX.Element;
	text?: String;
	extraStyles?: any;
}) => {
	const [visibility, changeVisibility] = React.useState(false);
	let content = props.text ? props.text : "Kontaktieren Sie uns!";

	React.useEffect(() => {
		disableScrolling(visibility);
	}, [visibility]);

	return (
		<>
			<span onClick={() => changeVisibility(!visibility)}>
				<button type="button" className={classes.button}>
					{content}
				</button>
			</span>
			<Modal
				visibility={visibility}
				changeVisibility={changeVisibility}
				extraStyling={props.extraStyles}>
				{props.children ? (
					props.children
				) : (
					<>
						<h5 className={"mb-25"}>Kontakt</h5>
						<p>regionalplan & uvp</p>
						<p>planungsbüro peter stelzer GmbH</p>
						<p>Grulandstraße 2</p>
						<p className="mb-25">49832 Freren</p>
						<p className="mb-25">Telefon: 05902 / 503702-0</p>
						<p>
							Email:
							<a
								className="ml-5 primaryColor"
								href="mailto:info@regionalplan-uvp.de">
								info@regionalplan-uvp.de
							</a>
						</p>
					</>
				)}
			</Modal>
		</>
	);
};

export default ModalButton;
