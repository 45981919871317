import classes from "./PageIntro.module.css";
import Banner from "../Banner/Banner";
import ArrowDown from "../../assets/arrowDown";
import SingleDownload from "../../Dokumente/SingleDownload";
import styled from "styled-components";

const DownloadProtocolButton = styled.button`
	font-size: 14px;
	height: 50px;
	width: 80%;
	font-weight: 600;
	background-color: var(--tertiaryColor);
	border: none;
	border-radius: 10px;
	color: var(--textColor);
	cursor: pointer;
	padding: 0px 30px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		color: white;
		text-decoration: underline;
	}
	
  a {
		all: unset;
	}
	
`;


const PageIntro = (props: {
	name: string;
	downloadLink?: string;
	image?: string;
	svg?: JSX.Element;
	text: any;
	secondaryLink?: { clicked: () => void; title: string };
	projektepage?: boolean;
	protocolDownloadLinks?: { name: string; date: Date; pdf: string; }[];
}) => {
	const content = props.image ? (
		<div className={"container " + classes.container}>
			<div className={"row"}>
				{/* TODO: These images should be adjusted later on (see parent component) */}
				<img
					src={props.image}
					className={"col-5"}
					// TODO: Change this to the corresponding description of the image
					alt="Naturbild der Dorfregion"
					loading={"lazy"}
				/>
				<div className={"col-7"}>
					<div className={"row"}>
						<div className={classes.text}>{props.text}</div>
						{props.downloadLink && (
							<div className={classes.contactBtn}>
								{/* TODO: paste the downloadLink in */}
								<SingleDownload downloadLink={props.downloadLink} />
							</div>
						)}
						{props.protocolDownloadLinks && (
							props.protocolDownloadLinks
								.sort((a, b) => b.date.getTime() - a.date.getTime())
								.map( link =>
									<div key={link.name} className={"col-12 " + classes.downloadProtocolBtn}>
										<DownloadProtocolButton>
											<a href={link.pdf} target="_blank" download={link.name}>{link.name}</a>
										</DownloadProtocolButton>
									</div>
								)
						)}

					</div>
				</div>
			</div>
			<div className={"row mt-25"}>
				<div className={"col-6"}>
					<h3 className="mb-25">Förderchancen für Privatpersonen</h3>
					<p style={{textAlign: "justify", fontSize: "medium"}} className="mb-25">Eigentümer*innen von historischen, landschaftstypischen und ortsbildprägenden Gebäuden haben die
						Möglichkeit, Fördermittel für Sanierungs- und Gestaltungsarbeiten, die das äußere Erscheinungsbild eines
						Gebäudes verbessern, zu erhalten. Dazu zählen Neu-, Aus- und Umbaumaßnahmen an sichtbaren Außenelementen,
						wie Fassaden, Dachflächen, Fenstern, Toren und Türen, in dorftypischer Gestaltung und Materialwahl sowie
						Dämmungs- und statisch notwendige Arbeiten. Dies schließt Maßnahmen an Haus, Hof, Garten und Grünflächen
						ein. Erweiterte Fördermöglichkeiten, wie ein Innenausbau, können bei Um- und Nachnutzung des Gebäudes
						förderfähig sein.</p>
				</div>
				<div className={"col-6"}>
					<h3 className="mb-25">Was wird gefördert?</h3>
					<p style={{textAlign: "justify", fontSize: "medium"}} className="mb-25">Die baulich-gestalterischen Merkmale, die ein Gebäude als ortsbild- oder landschaftsprägend kennzeichnen
						sowie Empfehlungen für private Projektmaßnahmen sind im Dorfentwicklungsplan festgehalten.</p>
				</div>
				<div className={"col-6"}>
					<h3 className="mb-25">Förderchancen für Vereine</h3>
					<p style={{textAlign: "justify", fontSize: "medium"}} className="mb-25">Vereine und Verbände, insbesondere gemeinnützig anerkannte, können von den vielfältigen Fördermöglichkeiten
						erheblich profitieren! Diese reichen von der multifunktionalen und barrierefreien Modernisierung eines
						Dorfgemeinschaftshauses oder einer Einrichtungen zur Gemeinschafts- und Nahversorgung bis hin zur
						Neugestaltung oder Aufwertung einer Vereinsanlage oder eines Dorfplatzes zu einem Mehrgenerationenplatz.</p>
				</div>
				<div className={"col-6"}>
					<h3 className="mb-25">Möchten Sie mehr darüber erfahren, welche Möglichkeiten sich für Ihr Objekt oder Verein
						bieten?</h3>
					<p style={{textAlign: "justify", fontSize: "medium"}} className="mb-25">Die Inanspruchnahme von Beratungsleistung zur Förderantragstellung ist kostenlos. Nutzen Sie die Chance,
						nehmen Sie gern Kontakt auf. Gerne informiert und berät Sie ein:e Mitarbeiter:in des Planungsbüros
						regionalplan & uvp p. stelzer GmbH unverbindlich vor Ort.</p>
				</div>
			</div>
		</div>
	) : (
		<div className={"container " + classes.container}>{props.text}</div>
	);

	const secondaryLink = props.secondaryLink ? (
		<button onClick={props.secondaryLink?.clicked}>
			<ArrowDown classes={classes.arrow}/>
			{props.secondaryLink.title}
		</button>
	) : null;

	return (
		<>
			<div className={"row " + classes.mainWrapper}>
				<Banner
					headingLevel={1}
					content={props.name}
					svg={props.svg}
					projektepage={props.projektepage}
				/>
				{secondaryLink}
			</div>
			{content}
		</>
	);
};

export default PageIntro;
