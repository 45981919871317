import {NavLink, Route} from "react-router-dom";
import classes from "./MainMenu.module.scss";
import slugify from "react-slugify";
import {loadAllDorfentwicklungen} from "../../../Projects/Dorfentwicklung";

export const MainMenu = () => {
	const dorfentwicklungen = loadAllDorfentwicklungen();
	return (
		<nav className={classes.menu}>
			{dorfentwicklungen.map((dorfentwicklung) => {
				return (
					<NavLink to={"/" + slugify(dorfentwicklung.name)} activeClassName={classes.active} key={dorfentwicklung.name}>
						{dorfentwicklung.name}
					</NavLink>
				);
			})}

			<NavLink to={`/friesoythe`} activeClassName={classes.active}>
				Kanaldörfer Friesoythe
			</NavLink>

			<NavLink to={`/AnKe`} activeClassName={classes.active}>
				Ankum Kettenkamp - AnKe
			</NavLink>

			<NavLink to={`/sustrum`} activeClassName={classes.active}>
				Niederlangen-Oberlangen-Sustrum-Landegge
			</NavLink>

			<NavLink to={`/nordhorn`} activeClassName={classes.active}>
				Nordhorn-Neuenhaus-Lage
			</NavLink>

			<NavLink to={`/dersum`} activeClassName={classes.active}>
				Dersum–Kluse–Walchum
			</NavLink>
		</nav>
	);
};
