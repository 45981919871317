import Banner from "../Components/Banner/Banner";
import classes from "./Home.module.css";
import ImagePattern from "../Components/ImagePattern/ImagePattern";
import TeamSection from "./Team/Team";
import TextSection2 from "../Components/TextSection/TextSection2";
import Schwerpunkte from "./Schwerpunkte/Schwerpunkte";
import ModalButton from "../Components/ModalButton/ModalButton";
import { Handbuchdownload } from "./Handbuchdownload/Handbuchdownload";
import Divider from "../Components/Divider/Divider";
import {LeitfadenDownload} from "./LeitfadenDownload/LeitfadenDownload";
// import CustomInstagramBanner from "../Components/CustomInstagramBanner/CustomInstagramBanner";

const Home = () => {
	return (
		<div className={classes.wrapper}>
			<Banner
				headingLevel={1}
				content="<strong>Nachhaltige</strong> Regional- & Dorfentwicklung"
				svg={<ImagePattern />}
			/>

			<TextSection2 />
			{/* TODO: Add new Handbuch */}
			<div className="mb-100"></div>
			<Divider />
			<Handbuchdownload />
			<Divider />
			<LeitfadenDownload />
			<TeamSection />
			<div className="mb-100"></div>
			<Schwerpunkte />
			{/* <CustomInstagramBanner /> */}
			<div className="mb-100"></div>
			<ModalButton />
		</div>
	);
};

export default Home;
