import image from "../../assets/Images/HomepageImage.jpg";
import classes from "./TextSection2.module.css";

const TextSection2 = () => {
	return (
		<>
			<div className={"container mb-100 " + classes.wrapper}>
				<h2 className={classes.heading}>
					HERZLICH <span className="primaryColor">WILLKOMMEN!</span>
				</h2>
				<div className={"row " + classes.contentWrapper}>
					<div className={classes.heading + " mb-50 "}></div>
					<div className={"col-12 " + classes.textSection}>
						<p className={"mt-50"}>
							Hier erfahren Sie mehr über die Dorfentwicklungsprozesse unseres
							Planungsbüros regionalplan & uvp.
						</p>
						<p className={"mb-50 mt-50"}>
							Wir
							<strong> BEGLEITEN - BERATEN - MODERIEREN</strong> Ihre
							Entwicklung im ländlichen Raum hin zu einer positiveren Zukunft.
						</p>
						<p>
							Sie kennen die Potentiale Ihrer Region - gemeinsam machen wir mehr
							daraus!
						</p>
					</div>
				</div>
				<div className={"col-12 " + classes.imageWrapper}>
					<img
						src={image}
						className={classes.image}
						alt="Team von Regionalplan UVP"
						loading="lazy"
					/>
				</div>
			</div>
		</>
	);
};

export default TextSection2;
