import classes from "./Schwerpunkte.module.css";
import Schwerpunkt from "./Schwerpunkt/Schwerpunkt";
import Divider from "../../Components/Divider/Divider";
import {
	faUsers,
	faMapMarkerAlt,
	faGlobe,
	faTree,
	faPaw,
	faChartArea,
} from "@fortawesome/free-solid-svg-icons";

const Schwerpunkte = () => {
	return (
		<>
			<Divider />
			<div className={classes.wrapper}>
				<h2 className="mb-75 mt-100">
					Unsere <span className={classes.coloredHeading}>Schwerpunkte</span>
				</h2>
				<div className="fullWidthContainer">
					<div className={"row"}>
						<Schwerpunkt
							icon={faMapMarkerAlt}
							heading={"Regional- und Bauleitplanung"}
						/>

						<Schwerpunkt
							icon={faGlobe}
							heading={"Landschaftsarchitektur / Ausführungsplanung"}
						/>

						<Schwerpunkt icon={faTree} heading={"UVS und Landschaftsplanung"} />
					</div>
					<div className={"row"}>
						<Schwerpunkt icon={faPaw} heading={"Artenschutz"} />

						<Schwerpunkt
							icon={faChartArea}
							heading={"Monitoring und Erfassung"}
						/>

						<Schwerpunkt icon={faUsers} heading={"Dorfentwicklungen"} />
					</div>
				</div>
			</div>
			<Divider />
		</>
	);
};

export default Schwerpunkte;
