import SingleDownload from "../../Dokumente/SingleDownload";
import classes from "./LeitfadenDownload.module.scss";
import LeitfadenImage from "../../assets/Images/Leitfaden/Leitfaden.jpg";
import LeitfadenPdf from "../../assets/Images/Leitfaden/LeitfadenGestaltungskriterienBaumaßnahmen.pdf";

export const LeitfadenDownload = () => {
	return (
		<div className={"container " + classes.container}>
			<div className={"row"}>
				{/* TODO: These images should be adjusted later on (see parent component) */}

				<div className={"col-7 " + classes.text}>
					<p>Wie kann die ortstypische Charakteristik eines Gebäudes erhalten bleiben?</p>
					<p>
						Dieser Leitfaden veranschaulicht die ortstypischen Gebäude, Gestaltungselemente und -formen in illustrierten Bildern und Grafiken und gibt in kompakten Erklärungen über die Vorgaben zur Gestaltung ortstypischer Gebäude in der Dorfentwicklung. Die zugehörigen Gestaltungshinweise richten sich insbesondere an private Eigentümer:innen, die im Rahmen der Förderphase der Dorfentwicklung ihre ortstypischen Gebäude und Höfe sanieren möchten.
					</p>
					<p>Der Leitfaden "Gestaltungskriterien für Baumaßnahmen ortstypischer Gebäude"</p>
				</div>
				<img
					src={LeitfadenImage}
					className={"col-5"}
					// TODO: Change this to the corresponding description of the image
					alt="Leitfaden"
					loading={"lazy"}
				/>
				<div className={"col-7 " + classes.contactBtn}>
					{/* TODO: paste the downloadLink in */}
					<SingleDownload downloadLink={LeitfadenPdf} text="Download Leitfaden" />
				</div>
			</div>
		</div>
	);
};
