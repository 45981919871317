import React from "react";
import PageIntro from "../Components/PageIntro/PageIntro";
import Divider from "../Components/Divider/Divider";
import classes from "./Project.module.scss";

import { useLocation } from "react-router-dom";
import SustrumBanners from "./Sustrum/SustrumBanners";
import styled from "styled-components";

const Paragraph = styled.p`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

interface IProjectProps {
	downloadLink?: string;
	nameKanaldörfer: string;
	image: string;
	svg: JSX.Element;
	dorfentwicklung: string;
	wappen?: string;
	banner?: JSX.Element;
	creationDate?: Date;
	protocolDownloadLinks?: { name: string; date: Date; pdf: string; }[];
	text: string;
}

const Project = (props: IProjectProps) => {
	const introduction =
		<Paragraph className="mb-25">
			<div>{props.text}</div>
			<div>{"Dorfentwicklungsplan: " + props.nameKanaldörfer}</div>
			{props.creationDate && (<div>Erstellt in {props.creationDate.getFullYear()}</div>)}
		</Paragraph>

	let documents = React.useRef(document.createElement("div"));
	const location: any = useLocation();

	const scrollToDocuments = (documents: any) => {
		const top = documents.current.getBoundingClientRect().top;
		window.scrollBy(0, top - 90);
	};

	const startAtDocuments = () => {
		if (location.state?.documents === true) {
			scrollToDocuments(documents);
		}
	};

	React.useEffect(startAtDocuments, [location.state?.categories]);

	return (
		<div className={classes.wrapper}>
			<PageIntro
				name={props.nameKanaldörfer}
				image={props.image}
				text={introduction}
				svg={props.svg}
				projektepage={true}
				downloadLink={props.downloadLink}
				protocolDownloadLinks={props.protocolDownloadLinks}
			/>
			<div ref={documents} id="documents">
				{props.banner && (
					<>
						<Divider /> <SustrumBanners />
					</>
				)}
			</div>
		</div>
	);
};

export default Project;
