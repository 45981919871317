import classes from "./Banner.module.css";

interface IBannerProps {
	headingLevel: number;
	content: string;
	svg?: JSX.Element;
	projektepage?: boolean;
}

const Banner = (props: IBannerProps) => {
	let heading;
	let bannerClass;
	let svg;
	let wrapperClass = props.projektepage ? classes.smallHeading : "";
	switch (props.headingLevel) {
		case 1:
			heading = <h1 dangerouslySetInnerHTML={{ __html: props.content }}></h1>;
			bannerClass = classes.Banner1;
			break;
		default:
			heading = <h2 dangerouslySetInnerHTML={{ __html: props.content }}></h2>;
			bannerClass = classes.Banner2;
	}

	if (props.svg) {
		bannerClass = classes.ImageBanner;
		svg = <div className={classes.image}>{props.svg}</div>;
	}

	return (
		<div className={bannerClass + " " + wrapperClass}>
			{heading}
			{svg}
		</div>
	);
};

export default Banner;
