import classes from "./Footer.module.css";
import LogoGrayscale from "./LogoGrayscale";
import FooterMenu from "./FooterMenu";

const Footer = () => {
	const menu: JSX.Element = (
		<FooterMenu
			className={classes.specificMenu}
		/>
	);

	return (
		<>
			<div className={"mt-100"} />
			<div className={classes.placeholder} />
			<div className={classes.outerWrapper}>
				<div className={"row " + classes.container}>
					<div className={"container left "}>
						<div className="col-4">
							<h5>Kontakt</h5>
							<p>regionalplan & uvp</p>
							<p>planungsbüro peter stelzer GmbH</p>
							<p>Grulandstraße 2</p>
							<p className="mb-25">49832 Freren</p>
							<p className="mb-25">Telefon: 05902 / 503702-0</p>
							<p>
								Email:
								<a className="ml-5" href="mailto:info@regionalplan-uvp.de">
									info@regionalplan-uvp.de
								</a>
							</p>
						</div>
						<div className={"col-4 col-6-sm " + classes.rechtliches}>
							<h5>Rechtliches</h5>
							<a href="https://www.regionalplan-uvp.de/impressum/">Impressum</a>
							<a href="https://www.regionalplan-uvp.de/datenschutz/">
								Datenschutz
							</a>
						</div>
						<div className={"col-4 col-6-sm " + classes.seiten}>
							<h5>Seiten</h5>
							{menu}
						</div>
						<div className="col-12 mt-25 ">
							<LogoGrayscale />
						</div>
					</div>
				</div>
				<div className={"row " + classes.copyright}>
					<div className={"container " + classes.copyrightBox}>
						<p>© Copyright 2023 regionalplan & uvp</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
