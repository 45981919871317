import SingleDownload from "../../Dokumente/SingleDownload";
import classes from "./Handbuchdownload.module.scss";
import HandbuchImage from "../../assets/Images/Dorfentwicklungshandbuch/Handbuch.png";
import HandbuchPDF from "../../assets/Images/Dorfentwicklungshandbuch/HandbuchPDF.pdf";

export const Handbuchdownload = () => {
	return (
		<div className={"container " + classes.container}>
			<div className={"row"}>
				{/* TODO: These images should be adjusted later on (see parent component) */}
				<img
					src={HandbuchImage}
					className={"col-5"}
					// TODO: Change this to the corresponding description of the image
					alt="Naturbild der Dorfregion"
					loading={"lazy"}
				/>

				<div className={"col-7 " + classes.text}>
					<p>Was ist eigentlich Dorfentwicklung?</p>
					<p>
						Dieses Handbuch ist Ihr begleitendes Nachschlagewerk zu den Fragen,
						die sich während des Prozesses in der Dorfentwicklung ergeben: Was
						ist der Arbeitskreis? Wer beteiligt sich an den
						Küchentischgesprächen? Welche Schritte benötigt ein Förderantrag?
						Mithilfe von einfachen Erklärungen und anschaulichen Grafiken
						erhalten Sie einige Antworten sowie einen Überblick über das Ziel,
						die Struktur und den Ablauf des Förderprogrammes.
					</p>
					<p>Das Handbuch Dorfentwicklung</p>
					<p>Erstellt von regionalplan & uvp in 2023</p>
				</div>
				<div className={"col-4 " + classes.space}></div>
				<div className={"col-7 " + classes.contactBtn}>
					{/* TODO: paste the downloadLink in */}
					<SingleDownload downloadLink={HandbuchPDF} text="Download Handbuch" />
				</div>
			</div>
		</div>
	);
};
