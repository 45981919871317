import React from "react";
import classes from "./ScrollTopArrow.module.css";
import ArrowSVG from "./TopArrowSVG";

const ScrollTopArrow = () => {
	const [showScroll, setShowScroll] = React.useState(false);
	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 200) {
			setShowScroll(true);
		} else if (showScroll && window.pageYOffset <= 200) {
			setShowScroll(false);
		}
	};
	window.addEventListener("scroll", checkScrollTop);
	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<div
			className={classes.wrapper}
			style={{ height: 40, display: showScroll ? "flex" : "none" }}>
			<ArrowSVG clicked={scrollTop} />
		</div>
	);
};

export default ScrollTopArrow;
