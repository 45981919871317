import { useEffect, useState } from "react";

export function useMediaQuery(query: string) {
	const [match, setMatch] = useState(window.matchMedia(query).matches);

	useEffect(() => {
		const mediaQuery = window.matchMedia(query);

		const onChange = () => {
			setMatch(mediaQuery.matches);
		};

		//Old Browsers
		if (typeof mediaQuery.addEventListener === "function") {
			mediaQuery.addEventListener("change", onChange);
		} else {
			mediaQuery.addListener(onChange);
		}
		setMatch(mediaQuery.matches);

		return () => {
			//Old Browsers
			if (typeof mediaQuery.removeEventListener === "function") {
				mediaQuery.removeEventListener("change", onChange);
			} else {
				mediaQuery.removeListener(onChange);
			}
		};
	}, [query]);
	return match;
}
