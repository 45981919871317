import clsx from "clsx";
import { NavLink } from "react-router-dom";
import classes from "../Navbar/Navbar.module.scss";

const FooterMenu = (props: {
	className?: string;
}) => {
	return (
		<nav className={clsx(props.className)}>
			<NavLink
				activeClassName={classes.active}
				exact={true}
				className={clsx(classes.Projects)}
				to={`/`}>
				Start
			</NavLink>

			<NavLink
				activeClassName={classes.active}
				className={clsx(classes.Projects)}
				to={`/friesoythe`}>
				Kanaldörfer Friesoythe
			</NavLink>

			<NavLink
				activeClassName={classes.active}
				className={clsx(classes.Projects)}
				to={`/AnKe`}>
				Ankum Kettenkamp - AnKe
			</NavLink>

			<NavLink
				activeClassName={classes.active}
				className={clsx(classes.Projects)}
				to={`/sustrum`}>
				Niederlangen-Oberlangen-Sustrum-Landegge
			</NavLink>

			<NavLink
				activeClassName={classes.active}
				className={clsx(classes.Projects)}
				to={`/nordhorn`}>
				Nordhorn-Neuenhaus-Lage
			</NavLink>

			<NavLink
				activeClassName={classes.active}
				className={clsx(classes.Projects)}
				to={`/dersum`}>
				Dersum–Kluse–Walchum
			</NavLink>

			<NavLink
				activeClassName={classes.active}
				className={clsx(classes.Projects)}
				to={`/luenne`}>
				Lünne
			</NavLink>

			<NavLink
				activeClassName={classes.active}
				className={clsx(classes.Projects)}
				to={`/zwischen-beeke-und-bach`}>
				Zwischen Beeke und Bach
			</NavLink>

			<NavLink
				activeClassName={classes.active}
				className={clsx(classes.Projects)}
				to={`/beidseits-der-lethe`}>
				Beidseits der Lethe
			</NavLink>
		</nav>
	);
};

export default FooterMenu;
