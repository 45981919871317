import "./App.css";
import Navbar from "./Navbar/Navbar";
import Home from "./Home/Home";
import Project from "./Projects/Project";
import SustrumBanners from "./Projects/Sustrum/SustrumBanners";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop";
import ScrollTopArrow from "./Components/ScrollTopArrow/ScrollTopArrow";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./simple-grid.css";
import { loadAllDorfentwicklungen} from "./Projects/Dorfentwicklung";
import slugify from 'react-slugify';

import ImagePatternDefault from "./Components/ImagePattern/ImagePattern";
// Friesoythe
import FriesoythePlan from "./assets/Plans/2022-06-04-DE-Plan-Kanaldoerfer_V02.pdf";
import ImagePatternFriesoythe from "./Components/ImagePattern/ImagePattern2";
import FriesoytheBannerImage from "./assets/Images/planImages/TitelbildFriesoythe.jpg";
// AnKe
import AnKePlan from "./assets/Plans/DE-Plan-AnKe.pdf";
import ImagePatternAnkum from "./Components/ImagePattern/ImagePatternAnke";
import AnKeBannerImage from "./assets/Images/planImages/TitelbildAnKe.jpg";
// Sustrum
import SustrumPlan from "./assets/Plans/DE-Plan_LOSN.pdf";
import SustrumBannerImage from "./assets/Images/planImages/TitelbildLOSN.jpg";
// Nordhorn
import ImagePatternNordhorn from "./Components/ImagePattern/ImagePatternNordhorn";
import NordhornPlan from "./assets/Plans/DorfentwicklungsplanNordhorn.pdf";
import NordhornBannerImage from "./assets/Images/planImages/TitelbildNordhornNeuenhausLage.jpg";
// Derhorn
import ImagePatternDersum from "./Components/ImagePattern/ImagePatternDersum";
import DersumPlan from "./assets/Plans/2021-06-22-Dorfentwicklungsplan_Sode_WEB.pdf";
import DersumBannerImage from "./assets/Images/planImages/TitelbildDresumKluseWalchum.jpg";

const App = () => {
	const dorfentwicklungen = loadAllDorfentwicklungen();
	return (
		<div className="App">
			<Router>
				<ScrollToTop>
					<Route exact path="/">
						<Navbar />
						<Home />
						<Footer />
					</Route>
					<Route path="/friesoythe">
						<Navbar />
						<Project
							nameKanaldörfer={"Dorfregion Kanaldörfer Friesoythe"}
							dorfentwicklung={"/friesoythe"}
							text={"In der Dorfentwicklung kommt es auf die Ideen und Visionen der Bürger*innen an. Gemeinsam wurden verschiedenste Projektideen entwickelt und in diesem Dorfentwicklungsplan festgehalten."}
							svg={<ImagePatternFriesoythe />}
							image={FriesoytheBannerImage}
							creationDate={new Date("2021")}
							downloadLink={FriesoythePlan}
						/>
						<Footer />
					</Route>
					<Route path="/AnKe">
						<Navbar />
						<Project
							nameKanaldörfer={"Dorfregion AnKe Ankum – Kettenkamp"}
							dorfentwicklung={"/AnKe"}
							text={"In der Dorfentwicklung kommt es auf die Ideen und Visionen der Bürger*innen an. Gemeinsam wurden verschiedenste Projektideen entwickelt und in diesem Dorfentwicklungsplan festgehalten."}
							svg={<ImagePatternAnkum />}
							image={AnKeBannerImage}
							creationDate={new Date("2022")}
							downloadLink={AnKePlan}
						/>
						<Footer />
					</Route>
					<Route path="/sustrum">
						<Navbar />
						<Project
							nameKanaldörfer={"Niederlangen-Oberlangen-Sustrum-Landegge"}
							dorfentwicklung={"/sustrum"}
							text={"In der Dorfentwicklung kommt es auf die Ideen und Visionen der Bürger*innen an. Gemeinsam wurden verschiedenste Projektideen entwickelt und in diesem Dorfentwicklungsplan festgehalten."}
							svg={<ImagePatternDefault />}
							image={SustrumBannerImage}
							banner={<SustrumBanners />}
							creationDate={new Date("2022")}
							downloadLink={SustrumPlan}
						/>
						<Footer />
					</Route>
					<Route path="/nordhorn">
						<Navbar />
						<Project
							nameKanaldörfer={"Nordhorn-Neuenhaus-Lage"}
							dorfentwicklung={"/nordhorn"}
							text={"In der Dorfentwicklung kommt es auf die Ideen und Visionen der Bürger*innen an. Gemeinsam wurden verschiedenste Projektideen entwickelt und in diesem Dorfentwicklungsplan festgehalten."}
							svg={<ImagePatternNordhorn />}
							image={NordhornBannerImage}
							creationDate={new Date("2020")}
							downloadLink={NordhornPlan}
						/>
						<Footer />
					</Route>
					<Route path="/dersum">
						<Navbar />
						<Project
							nameKanaldörfer={"Dersum–Kluse–Walchum"}
							dorfentwicklung={"/dersum"}
							text={"In der Dorfentwicklung kommt es auf die Ideen und Visionen der Bürger*innen an. Gemeinsam wurden verschiedenste Projektideen entwickelt und in diesem Dorfentwicklungsplan festgehalten."}
							svg={<ImagePatternDersum />}
							image={DersumBannerImage}
							creationDate={new Date("2021")}
							downloadLink={DersumPlan}
						/>
						<Footer />
					</Route>
					{dorfentwicklungen.map((dorfentwicklung) => {
						return (
							<Route path={"/" + slugify(dorfentwicklung.name)} key={dorfentwicklung.name}>
								<Navbar/>
								<Project
									nameKanaldörfer={dorfentwicklung.name}
									dorfentwicklung={"/" + slugify(dorfentwicklung.name)}
									text={dorfentwicklung.text}
									svg={<ImagePatternDefault/>}
									image={dorfentwicklung.coverImage}
									creationDate={dorfentwicklung.creationDate}
									protocolDownloadLinks={dorfentwicklung.protocols}
								/>
								<Footer/>
							</Route>
						);
					})}
				</ScrollToTop>
				<ScrollTopArrow />
			</Router>
		</div>
	);
};

export default App;
