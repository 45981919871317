const dorfentwicklungenJson = require.context('../cms/dorfentwicklungen', false, /\.json$/)

export type Dorfentwicklung = {
  name: string
  text: string
  creationDate?: Date
  dorfentwicklungsplan?: string
  coverImage: string
  order: number
  protocols: {
    name: string
    date: Date
    pdf: string
  }[]
}

export const loadAllDorfentwicklungen = (): Dorfentwicklung[] => {
  return dorfentwicklungenJson.keys().map((key) => {
    const fileName = key.replace("./", "");
    const resource = require(`../cms/dorfentwicklungen/${fileName}`);
    return parseDorfentwicklungFromFile(JSON.stringify(resource));
  }).sort((a, b) => b.order - a.order)
}

export const parseDorfentwicklungFromFile = (jsonString: string): Dorfentwicklung => {

  const plainParseResult =  JSON.parse(jsonString)

  const dorfentwicklungsplan = plainParseResult?.dorfentwicklungsplan ? `${plainParseResult.dorfentwicklungsplan.replace("/public", "")}` : undefined
  const creationDate = plainParseResult?.creationDate && plainParseResult?.creationDate !== '' ? new Date(plainParseResult.creationDate) : undefined
  return {
    ...plainParseResult,
    coverImage: `${plainParseResult.coverImage.replace("/public", "")}`,
    dorfentwicklungsplan,
    creationDate,
    protocols: plainParseResult.protocols?.map((protocol: {
      name: string
      date: string
      pdf: string
    }) => ({
      ...protocol,
      pdf: `${protocol.pdf.replace("/public", "")}`,
      date: new Date(protocol.date),
    })) || [],
  }
}