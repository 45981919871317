import classes from "./BannerFile.module.scss";

interface IBannerFileProps {
	title: string;
	image: string;
}

const BannerFile = (props: IBannerFileProps) => {
	return (
		<>
			<div className={classes.container}>
				<h3 className={classes.title}>{props.title}</h3>
				<img className={classes.image} alt="" src={props.image}></img>
			</div>
		</>
	);
};

export default BannerFile;
